import React, { useRef, useState } from 'react';
import {
  Button,
  ExpandableSection,
  Form,
  FormField,
  Grid,
  Header,
  Input,
  Modal,
  SpaceBetween,
  Spinner } from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import { TVDP, VDPSchema } from 'src/types';
import VisitorAssetsTablePanel from 'src/components/common/Visitor/VisitorAssetsTablePanel';
import { useQuery } from '@tanstack/react-query';
import * as APIt from 'src/API';
import { debug } from 'src/utils/commonUtils';
import { queryVisitorAssetTypes } from 'src/components/utils';

export interface IAddVDPVendorProps{
  addVDPVendorCallback: Function;
  addVendor: boolean;
  cancelAddVDPVendorCallback: Function;
  id: number;
}

export default function AddVDPVendor(props: IAddVDPVendorProps) {
  debug(`AddVDPVendor() props is ${JSON.stringify(props)}`);

  const [errorText, setErrorText] = useState<string>();
  const [expandAssets, setExpandAssets] = useState<boolean>(false);
  const [vdpVendor, setVDPVendor] = useState<Partial<TVDP>>(
    {
      company: undefined,
      emailAddress: null,
      phoneNumber: null,
      id: props.id
    });
  const [vdpVendorAssets, setVDPVendorAssets] = useState<APIt.VisitorAsset[]>([]);

  const [bundle, isBundleLoading] = useBundle('components.SelfService.RequestVendorAccess.Vendors.AddVDPVendor');

  const firstNameInputRef = useRef<HTMLInputElement>(null);

  const AddVDPVendorButtonHandler = async () => {
    debug(`AddVDPVendor() AddVDPVendorButtonHandler() visitor is ${JSON.stringify(vdpVendor)}`);
    const parseResults = await VDPSchema.safeParseAsync(vdpVendor);
    debug(`AddVDPVendor() AddVDPVendorButtonHandler() parseResults is ${JSON.stringify(parseResults)}`);
    if (parseResults.success) {
      props.addVDPVendorCallback({
        ...vdpVendor,
        assets: vdpVendorAssets,
      });
      setVDPVendor(
        {
          company: undefined,
          emailAddress: null,
          phoneNumber: null,
          id: props.id
        });
    }
    if (!parseResults.success) {
      let errors = '';
      for (let issue of parseResults.error.issues) {
        errors += `${bundle.getMessage(issue.message)}; `;
      }
      setErrorText(errors);
    }
  };

  const cancelAddVDPVendorButtonHandler = () => {
    setVDPVendor(
      {
        company: undefined,
        emailAddress: null,
        phoneNumber: null,
        id: props.id
      });
    props.cancelAddVDPVendorCallback();
  };

  const visitorAssetTypesQuery = useQuery({
    queryKey: ['addVisitorAssetTypes'],
    queryFn: async () => await queryVisitorAssetTypes(),
    retry: 3
  });

  if (isBundleLoading) return <Spinner/>;

  return(
    <Modal
      header={
        <Header>
          {bundle.getMessage('add-vendor')}
        </Header>
      }
      onDismiss={() => props.cancelAddVDPVendorCallback()}
      size='large'
      visible={true}
    >
      <form onSubmit={async e => {
        e.preventDefault();
        await AddVDPVendorButtonHandler();
      }}>
        <Form
          actions={
            <SpaceBetween direction='horizontal' size='xs'>
              <Button
                data-testid='AddVDPVendorCancelButton'
                formAction='none'
                variant='normal'
                onClick={cancelAddVDPVendorButtonHandler}
              >
                {bundle.getMessage('cancel')}
              </Button>
              <Button
                data-testid='AddVDPVendorAddVDPVendorButton'
                variant='primary'
                onClick={async () => await AddVDPVendorButtonHandler()}
              >
                {props.addVendor ? bundle.getMessage('add-vendor') : bundle.getMessage('add-visitor')}
              </Button>
            </SpaceBetween>
          }
          errorText={errorText}
        >
          <Grid
            gridDefinition={
              [
                { colspan: { default: 12, xxs: 6 } },
                { colspan: { default: 12, xxs: 6 } },
                { colspan: { default: 12, xxs: 6 } },
                { colspan: { default: 12, xxs: 6 } },
                { colspan: { default: 12, xxs: 6 } }
              ]}
          >
            <FormField label={bundle.getMessage('first-name')}>
              <Input
                autoFocus
                data-testid='AddVDPVendorFirstNameInput'
                onChange={({detail}) => {
                  setVDPVendor(
                    {
                      ...vdpVendor,
                      firstName: detail.value == '' ? undefined : detail.value
                    });
                }}
                ref={firstNameInputRef}
                value={vdpVendor?.firstName || ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('last-name')}>
              <Input
                data-testid='AddVDPVendorLastNameInput'
                onChange={({detail}) => {
                  setVDPVendor(
                    {
                      ...vdpVendor,
                      lastName: detail.value == '' ? undefined : detail.value
                    });
                }}
                value={vdpVendor?.lastName || ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('company')}>
              <Input
                data-testid='AddVDPVendorCompanyInput'
                onChange={({detail}) => {
                  setVDPVendor(
                    {
                      ...vdpVendor,
                      company: detail.value == '' ? undefined : detail.value
                    });
                }}
                value={vdpVendor?.company || ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('email-address')}>
              <Input
                data-testid='AddVDPVendorEmailAddressInput'
                onChange={({detail}) => {
                  setVDPVendor(
                    {
                      ...vdpVendor,
                      emailAddress: detail.value == '' ? undefined : detail.value
                    });
                }}
                value={vdpVendor?.emailAddress || ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('phone-number')}>
              <Input
                data-testid='AddVDPVendorPhoneNumberInput'
                onChange={({detail}) => {
                  setVDPVendor(
                    {
                      ...vdpVendor,
                      phoneNumber: detail.value == '' ? undefined : detail.value
                    });
                }}
                value={vdpVendor?.phoneNumber || ''}
              />
            </FormField>
          </Grid>
          <ExpandableSection
            data-testid='AddVDPVendorShowAssetsToggle'
            expanded={expandAssets}
            header={expandAssets ? bundle.getMessage('hide-assets') : `${bundle.getMessage('show-assets')} (${vdpVendorAssets.length})` }
            onChange={() => setExpandAssets(!expandAssets)}
          >
            <VisitorAssetsTablePanel
             isTableLoading={false}
             readOnly={false}
             siteCode=''
             setVisitorAssets={setVDPVendorAssets}
             username=''
             visitorAssets={vdpVendorAssets}
             visitorAssetTypesQuery={visitorAssetTypesQuery}
             visitorId=''
           />
          </ExpandableSection>
        </Form>
      </form>
    </Modal>);
}