import React, { useRef, useState } from 'react';
import {
  Button,
  ExpandableSection,
  Form,
  FormField,
  Grid,
  Header,
  Input,
  Modal,
  SpaceBetween,
  Spinner } from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import { TVDP, VDPSchema } from 'src/types';
import VisitorAssetsTablePanel from 'src/components/common/Visitor/VisitorAssetsTablePanel';
import { useQuery } from '@tanstack/react-query';
import * as APIt from 'src/API';
import { debug } from 'src/utils/commonUtils';
import { queryVisitorAssetTypes } from 'src/components/utils';

export interface IEditVDPVendorProps{
  editVDPVendorCallback: Function;
  editVendor: boolean;
  cancelEditVDPVendorCallback: Function;
  id: number;
  vdpVendor: TVDP;
  visitorAssets: APIt.VisitorAsset[];
}

export default function EditVDPVendor(props: IEditVDPVendorProps) {
  debug(`EditVDPVendor() props is ${JSON.stringify(props)}`);

  const [errorText, setErrorText] = useState<string>();
  const [expandAssets, setExpandAssets] = useState<boolean>(false);
  const [vdpVendor, setVDPVendor] = useState<Partial<TVDP>>(props.vdpVendor);
  const [visitorAssets, setVisitorAssets] = useState<APIt.VisitorAsset[]>(props.visitorAssets);

  const [bundle, isBundleLoading] = useBundle('components.SelfService.RequestVendorAccess.Vendors.EditVDPVendor');

  const firstNameInputRef = useRef<HTMLInputElement>(null);

  const EditVDPVendorButtonHandler = async () => {
    debug(`EditVDPVendor() EditVDPVendorButtonHandler() visitor is ${JSON.stringify(vdpVendor)}`);
    const parseResults = await VDPSchema.safeParseAsync(vdpVendor);
    debug(`EditVDPVendor() EditVDPVendorButtonHandler() parseResults is ${JSON.stringify(parseResults)}`);
    if (parseResults.success) {
      props.editVDPVendorCallback({
        ...vdpVendor,
        assets: visitorAssets,
      });
      setVDPVendor(
        {
          company: undefined,
          emailAddress: '',
          phoneNumber: '',
          id: props.id
        });
    }
    if (!parseResults.success) {
      let errors = '';
      for (let issue of parseResults.error.issues) {
        errors += `${bundle.getMessage(issue.message)}; `;
      }
      setErrorText(errors);
    }
  };

  const cancelEditVDPVendorButtonHandler = () => {
    setVDPVendor(props.vdpVendor);
    props.cancelEditVDPVendorCallback();
  };

  const visitorAssetTypesQuery = useQuery({
    queryKey: ['addVisitorAssetTypes'],
    queryFn: async () => await queryVisitorAssetTypes(),
    retry: 3
  });

  if (isBundleLoading) return <Spinner/>;

  return(
    <Modal
      header={
        <Header>
          {props.editVendor ? bundle.getMessage('edit-vendor') : bundle.getMessage('edit-visitor')}
        </Header>
      }
      onDismiss={() => props.cancelEditVDPVendorCallback()}
      size='large'
      visible={true}
    >
      <form onSubmit={async e => {
        e.preventDefault();
        await EditVDPVendorButtonHandler();
      }}>
        <Form
          actions={
            <SpaceBetween direction='horizontal' size='xs'>
              <Button
                data-testid='EditVDPVendorCancelButton'
                formAction='none'
                variant='normal'
                onClick={cancelEditVDPVendorButtonHandler}
              >
                {bundle.getMessage('cancel')}
              </Button>
              <Button
                data-testid='EditVDPVendorEditVDPVendorButton'
                variant='primary'
              >
                {bundle.getMessage('save')}
              </Button>
            </SpaceBetween>
          }
          errorText={errorText}
        >
          <Grid
            gridDefinition={
              [
                { colspan: { default: 12, xxs: 6 } },
                { colspan: { default: 12, xxs: 6 } },
                { colspan: { default: 12, xxs: 6 } },
                { colspan: { default: 12, xxs: 6 } },
                { colspan: { default: 12, xxs: 6 } }
              ]}
          >
            <FormField label={bundle.getMessage('first-name')}>
              <Input
                autoFocus
                data-testid='EditVDPVendorFirstNameInput'
                onChange={({detail}) => {
                  setVDPVendor(
                    {
                      ...vdpVendor,
                      firstName: detail.value == '' ? undefined : detail.value
                    });
                }}
                ref={firstNameInputRef}
                value={vdpVendor?.firstName || ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('last-name')}>
              <Input
                data-testid='EditVDPVendorLastNameInput'
                onChange={({detail}) => {
                  setVDPVendor(
                    {
                      ...vdpVendor,
                      lastName: detail.value == '' ? undefined : detail.value
                    });
                }}
                value={vdpVendor?.lastName || ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('company')}>
              <Input
                data-testid='EditVDPVendorCompanyInput'
                onChange={({detail}) => {
                  setVDPVendor(
                    {
                      ...vdpVendor,
                      company: detail.value == '' ? undefined : detail.value
                    });
                }}
                value={vdpVendor?.company || ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('email-address')}>
              <Input
                data-testid='EditVDPVendorEmailAddressInput'
                onChange={({detail}) => {
                  setVDPVendor(
                    {
                      ...vdpVendor,
                      emailAddress: detail.value == '' ? undefined : detail.value
                    });
                }}
                value={vdpVendor?.emailAddress || ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('phone-number')}>
              <Input
                data-testid='EditVDPVendorPhoneNumberInput'
                onChange={({detail}) => {
                  setVDPVendor(
                    {
                      ...vdpVendor,
                      phoneNumber: detail.value == '' ? undefined : detail.value
                    });
                }}
                value={vdpVendor?.phoneNumber || ''}
              />
            </FormField>
          </Grid>
          <ExpandableSection
            data-testid='EditVDPVendorShowAssetsToggle'
            expanded={expandAssets}
            header={expandAssets ? bundle.getMessage('hide-assets') : `${bundle.getMessage('show-assets')} (${visitorAssets.length})` }
            onChange={() => setExpandAssets(!expandAssets)}
          >
            <VisitorAssetsTablePanel
             isTableLoading={false}
             readOnly={false}
             siteCode=''
             setVisitorAssets={setVisitorAssets}
             username=''
             visitorAssets={visitorAssets}
             visitorAssetTypesQuery={visitorAssetTypesQuery}
             visitorId=''
           />
          </ExpandableSection>
        </Form>
      </form>
    </Modal>);
}