import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Header,
  Modal,
  Pagination,
  SpaceBetween,
  Spinner,
  Table } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { ColumnDefinitions, PaginationLabels } from './table-config';
import { useBundle } from '@amzn/react-arb-tools';
import { TVisitor } from 'src/types';
import AddVisitor from 'src/components/common/Visitor/AddVisitor';
import EditVisitor from 'src/components/common/Visitor/EditVisitor';
import VisitorBulkLoad from '../../common/VisitorBulkAdd';
import VisitorAssetsTablePanel from 'src/components/common/Visitor/VisitorAssetsTablePanel';
import { debug } from 'src/utils/commonUtils';

export interface IVisitorsTablePanelProps{
  visitors: TVisitor[];
  setVisitorsCallback: Function;
}

export default function VisitorsTablePanel(props: IVisitorsTablePanelProps) {
  debug(`VisitorsTablePanel() props is ${JSON.stringify(props)}`);

  const [adding, setAdding] = useState<boolean>(false);
  const [allItems, setAllItems] = useState<TVisitor[]>(props.visitors);
  const [editing, setEditing] = useState<boolean>(false);
  const [hideTable, setHideTable] = useState<boolean>(false);
  const [id, setId] = useState<number>(1);
  const [selectedItems, setSelectedItems] = useState<TVisitor[]>([]);
  const [showAddVisitor, setShowAddVisitor] = useState<boolean>(false);
  const [showEditVisitor, setShowEditVisitor] = useState<boolean>(false);
  const [showVisitorBulkLoad, setShowVisitorBulkLoad] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.SelfService.RequestAmazonAirSiteAccess.Visitors.TablePanel');

  const pageSize = 5;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const removeSelected = () => {
    const currentItems = allItems;
    const newItems = currentItems.filter(al => !selectedItems.find(i => i.id == al.id));
    setSelectedItems([]);
    setAllItems(newItems);
    props.setVisitorsCallback(newItems);
  };

  const onSelectionChangeHandler = (detail: { selectedItems: React.SetStateAction<any[]> }) => {
    setSelectedItems(detail.selectedItems as TVisitor[]);
  };

  const addVisitorButtonHandler = () => {
    setShowAddVisitor(true);
  };

  const addVisitor = (visitor: TVisitor) => {
    debug(`VisitorsTablePanel() addVisitor() visitor is ${JSON.stringify(visitor)}`);
    setAdding(true);
    let i = id + 1;
    setId(i);
    props.setVisitorsCallback([...props.visitors, visitor]);
    setShowAddVisitor(false);
    setAdding(false);
  };

  const addBulkVisitors = (visitors: TVisitor[]) => {
    debug(`VisitorTablePanel() addBulkVisitors() visitors is ${JSON.stringify(visitors)}`);
    props.setVisitorsCallback([...allItems, ...visitors]);
    setId(visitors[visitors.length-1].id + 1);
  };

  const editVisitor = (visitor: TVisitor) => {
    debug(`VisitorsTablePanel() editVisitor() visitor is ${JSON.stringify(visitor)}`);
    setEditing(true);
    props.setVisitorsCallback([...(allItems.filter(v => v.id !== visitor.id)), visitor]);
    setShowEditVisitor(false);
    setEditing(false);
  };

  useEffect(() => {
    setSelectedItems([]);
    setAllItems(props.visitors);
  }, [props.visitors]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
    <div id='RequestAmazonAirSiteAccessVisitorsTableDiv' hidden={hideTable}>
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        header={
          <Header
            counter={`(${itemsCount().toString()})`}
          >
            {bundle.getMessage('external-visitors')}
          </Header>
        }
        footer={
          <SpaceBetween direction='vertical' size='xs'>
            <Box float='right'>
              <SpaceBetween key={'RequestAmazonAirSiteAccessBottomButtonsSpaceBetween'} size='s' direction='horizontal'>
                <Button
                  disabled={selectedItems.length !== 1}
                  onClick={() => setShowEditVisitor(true)}
                  variant='normal'
                >
                  {bundle.getMessage('edit')}
                </Button>
                <Button
                  data-testid='RequestAmazonAirSiteAccessVisitorsRemoveSelectedButton'
                  disabled={selectedItems.length == 0}
                  onClick={removeSelected}
                  variant='normal'
                >
                  {bundle.getMessage('remove-selected')}
                </Button>
                <Button
                  data-testid='RequestAmazonAirSiteAccessVisitorsAddExternalVisitorButton'
                  loading={adding}
                  onClick={addVisitorButtonHandler}
                  variant='primary'
                >
                  {bundle.getMessage('add-external-visitor')}
                </Button>
                <Button
                  data-testid='RequestAmazonAirSiteAccessVisitorsBulkAddExternalVisitorButton'
                  loading={adding}
                  onClick={() => setShowVisitorBulkLoad(true)}
                  variant='normal'
                >
                  {bundle.getMessage('bulk-add-visitors')}
                </Button>
              </SpaceBetween>
            </Box>
          </SpaceBetween>
        }
        items={items}
        selectionType='multi'
        selectedItems={selectedItems}
        onSelectionChange={({ detail }) =>
          onSelectionChangeHandler(detail)
        }
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        trackBy='id'
      />
      {selectedItems.length === 1 && selectedItems[0].assets && selectedItems[0].assets?.length > 0
      &&
      <Container header={bundle.getMessage('assets')}>
        <VisitorAssetsTablePanel
          isTableLoading={false}
          readOnly={true}
          siteCode=''
          setVisitorAssets={undefined}
          username=''
          visitorAssets={selectedItems[0].assets || []}
          visitorAssetTypesQuery={null}
          visitorId=''
        />
      </Container>
      }
    </div>
    {showAddVisitor
    &&
    <AddVisitor
      addVisitorCallback={addVisitor}
      addVendor={false}
      cancelAddVisitorCallback={() => setShowAddVisitor(false)} id={id}
      visitorTypeSelectable={false}
    />}
    {showVisitorBulkLoad
    &&
    <Modal
      header={bundle.getMessage('bulk-add-visitors')}
      onDismiss={() => setShowVisitorBulkLoad(false)}
      size='large'
      visible={showVisitorBulkLoad}
    >
      <VisitorBulkLoad
        addBulkVisitorsCallback={addBulkVisitors}
        addVendor={false}
        closeCallback={() => setShowVisitorBulkLoad(false)}
        id={id}
        visitorTypeSelectable={false}
      />
    </Modal>
    }
    {showEditVisitor
    &&
    <EditVisitor
      editVendor={false}
      editVisitorCallback={editVisitor}
      cancelEditVisitorCallback={() => setShowEditVisitor(false)}
      id={id}
      visitor={selectedItems[0]}
      visitorAssets={selectedItems[0]?.assets || []}
      visitorTypeSelectable={false}
    />}
    </>
  );
}