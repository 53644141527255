import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Modal, Pagination, SpaceBetween, Spinner, Table } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels } from './table-config';
import { useBundle } from '@amzn/react-arb-tools';
import { TVendor } from 'src/types';
import VisitorAssetsTablePanel from 'src/components/common/Visitor/VisitorAssetsTablePanel';
import { debug } from 'src/utils/commonUtils';
import AddVDPVendor from './AddVDPVendor';
import EditVDPVendor from './EditVDPVendor';
import VDPBulkAdd from '../../common/VDPBulkAdd';

export interface VendorsTablePanelPropsInterface {
  vendors: TVendor[];
  setVendorsCallback: Function;
  username: string;
  visitorId: string | undefined;
}

export default function VendorsTablePanel(props: VendorsTablePanelPropsInterface ) {
  debug(`VendorsTablePanel() props is ${JSON.stringify(props)}`);

  const [adding, setAdding] = useState<boolean>(false);
  const [allItems, setAllItems] = useState<TVendor[]>(props.vendors);
  const [editing, setEditing] = useState<boolean>(false);
  const [hideTable, setHideTable] = useState<boolean>(false);
  const [id, setId] = useState<number>(1);
  const [selectedItems, setSelectedItems] = useState<TVendor[]>([]);
  const [showAddVendor, setShowAddVendor] = useState<boolean>(false);
  const [showEditVendor, setShowEditVendor] = useState<boolean>(false);
  const [showVisitorBulkLoad, setShowVisitorBulkLoad] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.SelfService.RequestVendorAccess.Vendors.TablePanel');

  const pageSize = 10;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'username' }
    }
  );

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const addVendorsButtonHandler = async () => {
    setShowAddVendor(true);
  };

  const addVendor = (vendor: TVendor) => {
    debug(`VendorsTablePanel() addVendor() vendor is ${JSON.stringify(vendor)}`);
    setAdding(true);
    let i = id + 1;
    setId(i);
    props.setVendorsCallback([...allItems, vendor]);
    setShowAddVendor(false);
    setAdding(false);
  };

  const editVendor = (vendor: TVendor) => {
    debug(`VendorsTablePanel() editVendor() vendor is ${JSON.stringify(vendor)}`);
    setEditing(true);
    props.setVendorsCallback([...(allItems.filter(v => v.id !== vendor.id)), vendor]);
    setShowEditVendor(false);
    setEditing(false);
  };

  const removeSelected = () => {
    const currentItems = allItems;
    debug(`removeSelected() currentItems is ${JSON.stringify(currentItems)}`);
    debug(`removeSelected() selectedItems is ${JSON.stringify(selectedItems)}`);
    const newItems = currentItems.filter(al => !selectedItems.find(i => i.id == al.id));
    debug(`removeSelected() newItems is ${JSON.stringify(newItems)}`);
    setSelectedItems([]);
    setAllItems(newItems);
    props.setVendorsCallback(newItems);
  };

  const onSelectionChangeHandler = (detail: { selectedItems: React.SetStateAction<any[]> }) => {
    setSelectedItems(detail.selectedItems as TVendor[]);
  };

  const addBulkVendors = (vendors: TVendor[]) => {
    debug(`RequestVendorAcccess() addBulkVendors() visitors is ${JSON.stringify(vendors)}`);
    props.setVendorsCallback([...allItems, ...vendors]);
    setId(vendors[vendors.length-1].id + 1);
  };

  useEffect(() => {
    debug(`VendorsTablePanel() useEffect()[props.vendors] props.vendors is ${JSON.stringify(props.vendors)}`);
    setSelectedItems([]);
    setAllItems(props.vendors);
  }, [props.vendors])

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
    <div id='tableDiv' hidden={hideTable}>
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        header={
          <Header counter={`(${itemsCount().toString()})`}>
            {bundle.getMessage('vendors')}
          </Header>
        }
        footer={
          <>
          <SpaceBetween direction='vertical' size='xs'>
            <Box float='right'>
              <SpaceBetween direction='horizontal' size='s'>
                <Button
                  disabled={selectedItems.length !== 1}
                  onClick={() => setShowEditVendor(true)}
                  variant='normal'
                >
                  {bundle.getMessage('edit')}
                </Button>
                <Button
                  data-testid='RequestVendorAccessRemoveSelectedButton'
                  disabled={selectedItems.length == 0}
                  onClick={removeSelected}
                  variant='normal'
                >
                  {bundle.getMessage('remove-selected')}
                </Button>
                <Button
                  data-testid='RequestVendorAccessAddVendorButton'
                  loading={adding}
                  onClick={addVendorsButtonHandler}
                  variant='primary'
                >
                  {bundle.getMessage('add-vendor')}
                </Button>
                <Button
                  data-testid='RequestVendorAccessBulkAddVendorButton'
                  loading={adding}
                  onClick={() => setShowVisitorBulkLoad(true)}
                  variant='normal'
                >
                  {bundle.getMessage('bulk-add-vendors')}
                </Button>
              </SpaceBetween>
            </Box>
          </SpaceBetween>
          </>
        }
        items={items}
        selectionType='multi'
        selectedItems={selectedItems}
        onSelectionChange={({ detail }) =>
          onSelectionChangeHandler(detail)
        }
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        trackBy='id'
      />
      {selectedItems.length === 1 && selectedItems[0].assets && selectedItems[0].assets?.length > 0
      &&
      <Container header={bundle.getMessage('assets')}>
        <VisitorAssetsTablePanel
          isTableLoading={false}
          readOnly={true}
          siteCode=''
          setVisitorAssets={undefined}
          username={props.username}
          visitorAssets={selectedItems[0].assets || []}
          visitorAssetTypesQuery={null}
          visitorId=''
        />
      </Container>
      }
    </div>
    {showAddVendor
    &&
    <AddVDPVendor
      addVDPVendorCallback={addVendor}
      addVendor={true}
      cancelAddVDPVendorCallback={() => setShowAddVendor(false)} id={id}
    />}
    {showVisitorBulkLoad
    &&
    <Modal
      header={bundle.getMessage('bulk-add-vendors')}
      onDismiss={() => setShowVisitorBulkLoad(false)}
      size='large'
      visible={showVisitorBulkLoad}
    >
      <VDPBulkAdd
        addBulkVDPVendorsCallback={addBulkVendors}
        addVendor={true}
        closeCallback={() => setShowVisitorBulkLoad(false)}
        id={id}
      />
    </Modal>
    }
    {showEditVendor
    &&
    <EditVDPVendor
      editVendor={true}
      editVDPVendorCallback={editVendor}
      cancelEditVDPVendorCallback={() => setShowEditVendor(false)}
      id={id}
      vdpVendor={selectedItems[0]}
      visitorAssets={selectedItems[0]?.assets || []}
    />}
    </>
  );
}
