import * as React from "react";
import TopNavigation, { TopNavigationProps } from "@amzn/awsui-components-react/polaris/top-navigation";
import { Alert, Link, Spinner } from '@amzn/awsui-components-react';
import { ILanguage, Images, Languages, Modes, URLS } from '../constants/Constants';
import { useState } from "react";
import { useLocalizationContext } from "@amzn/react-arb-tools";
import { DEFAULT_LC_BUILDER } from "../constants/Constants";
import i18n from "src/i18n";
import { useBundle } from "@amzn/react-arb-tools";
import { UserPreferences as SelfServiceUserPreferences } from "./common/UserPreferences";
import { UseQueryResult } from "@tanstack/react-query";
import { UserPrefs } from "src/API";
import { IsItDown } from "./IsItDown";
import { debug } from '../utils/commonUtils';

interface ITopNav {
  admin: boolean;
  dailySummaryEmails: boolean;
  darkMode: boolean;
  disableAutoApproval: boolean;
  disableNewVendorDayPassRequests: boolean | undefined;
  enableSTVBRequests: boolean | undefined;
  enableDebugLogging: boolean;
  liveEmails:  boolean;
  mode: Modes;
  notificationTime: string | null;
  notificationTimezone: string | null;
  setAdminCallback: Function;
  setDisableAutoApprovalCallback: Function;
  setDarkModeCallback: Function;
  setEnableDebugLoggingCallback: Function;
  setShowSelectSite: Function;
  setTabCallback: Function;
  siteCode: string | undefined;
  username: string;
  userPrefsQuery: UseQueryResult<UserPrefs | null, unknown>;
  userCanManageVisitorsForSite: boolean;
}

export const TopNav = (props: ITopNav) => {
  debug(`TopNav() props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.Navigation');

  const [showUsageMessage, setShowUsageMessage] = useState<boolean>(true);

  const i18nStrings = {
    searchIconAriaLabel: 'Search',
    searchDismissIconAriaLabel: 'Close search',
    overflowMenuTriggerText: 'More',
    overflowMenuTitleText: 'More'};

  let stage = 'beta';
  debug(`TopNav() stage is ${stage}`);

  const [ title, setTitle ] = useState<string>(() => {
    let title = 'Welcome';
    if (stage.toLowerCase() !== 'prod') title += ` - ${stage.toUpperCase()}`;
    return title;
  });

  const { localizationContext, setLocalizationContext } = useLocalizationContext();

  const [ showSelfServiceUserPreferences, setShowSelfServiceUserPreferences ] = useState<boolean>(false);

	const languageHandleChange = (e: any) => {
		const language = e.detail.id;
		i18n.changeLanguage(language);
		setLocalizationContext(DEFAULT_LC_BUILDER.withLocale(language).build());
	};

  if (isBundleLoading) return <Spinner/>;

  let topMenuItems: TopNavigationProps.Utility[] = [
    {
      type: 'button',
      text: bundle.getMessage('user-preferences'),
      iconName: 'settings',
      ariaLabel: 'User Preferences',
      badge: false,
      disableUtilityCollapse: true,
      onClick: () => {
        setShowSelfServiceUserPreferences(true);
      },
    },
    {
      type: 'button',
      text: bundle.getMessage('guardian-hub'),
      ariaLabel: 'Guardian Hub',
      href: URLS.GuardianHub,
      external: true,
      badge: false,
      disableUtilityCollapse: true,
    },
    {
      type: 'menu-dropdown',
      text: bundle.getMessage('help'),
      items: [
        {
          id: "contact",
          text: bundle.getMessage('contact-us'),
          href: URLS.ContactUs,
          external: true,
          externalIconAriaLabel: " (opens in new tab)"
        },
        {
          id: "guide",
          text: bundle.getMessage('user-guide'),
          href: URLS.UserGuide,
          external: true,
          externalIconAriaLabel: " (opens in new tab)"
        },
      ]
    },
    {
      type: 'button',
      text: `${props.username}@`,
      iconName: 'user-profile',
      disableTextCollapse: true,
    }
  ];

  if (props.mode == Modes.MANAGEMENT)
    topMenuItems = [
      {
        type: 'button',
        iconName: 'edit', 
        text: props.siteCode ? `${props.siteCode} - ${bundle.getMessage('change-site')}` : bundle.getMessage('change-site'),
        onClick: () => {props.setShowSelectSite(true)}
      },
      {
        type: 'button',
        text: bundle.getMessage('permissions'),
        iconName: props.admin ? 'key' : props.userCanManageVisitorsForSite ? 'check' : 'status-warning',
      },
      {
        ariaLabel: 'Self Service',
        badge: false,
        disableUtilityCollapse: true,
        external: true,
        href: '',
        onClick: () => window.open('#/selfservice', 'welcomeSelfService'),
        text: bundle.getMessage('self-service'),
        type: 'button',
      },
      ...topMenuItems
    ];

  if (props.mode == Modes.SELF_SERVICE)
    topMenuItems = [
      {
        ariaLabel: 'Management',
        badge: false,
        disableUtilityCollapse: true,
        external: true,
        href: '',
        onClick: () => window.open('#/management', 'welcomeManagement'),
        text: bundle.getMessage('management'),
        type: 'button',
      },
      ...topMenuItems
    ];

  const closeSelfServiceUserPreferences = () => {
    setShowSelfServiceUserPreferences(false);
  };

  const getLanguage = (): ILanguage | undefined => {
    const language = Languages.find(l => l.id == localizationContext.getLocale());
    if (!language) return undefined;
    return language;
  };

  return (
    <>
      {
        showUsageMessage
        &&
        <Alert
          dismissible={true}
          onDismiss={() => setShowUsageMessage(false)}
          statusIconAriaLabel='Info'
          type='info'
        >
          {bundle.getMessage('usage-message').replace('visits.corp.amazon.com','')}
          <Link
            href={URLS.AmazonVisits}
            target="_blank"
          >
            Amazon Visits. 
          </Link>
        </Alert> 
      }
      <IsItDown />
      <TopNavigation
        i18nStrings={i18nStrings}
        identity={{
          href: props.mode == Modes.MANAGEMENT ? '/#/management' : '#',
          title: title,
          logo: {
            src: Images.home,
            alt: ''
          }
        }}
        data-testid='TopNav'
        utilities={topMenuItems}
      />
      {
        showSelfServiceUserPreferences &&
        <SelfServiceUserPreferences
          admin={props.admin}
          closeUserPreferencesCallback={closeSelfServiceUserPreferences}
          enableDebugLogging={props.enableDebugLogging}
          dailySummaryEmails={props.dailySummaryEmails}
          darkMode={props.darkMode}
          disableAutoApproval={props.disableAutoApproval}
          disableNewVendorDayPassRequests={props.disableNewVendorDayPassRequests}
          enableSTVBRequests={props.enableSTVBRequests}
          setDisableAutoApprovalCallback={props.setDisableAutoApprovalCallback}
          language={getLanguage()}
          liveEmails={props.liveEmails}
          mode={props.mode}
          notificationTime={props.notificationTime}
          notificationTimezone={props.notificationTimezone}
          setAdminCallback={props.setAdminCallback}
          setEnableDebugLoggingCallback={props.setEnableDebugLoggingCallback}
          username={props.username}
          userPrefs={props.userPrefsQuery.data}
          userPrefsIsLoading={props.userPrefsQuery.isLoading}
        />
      }
    </>
  );
}
