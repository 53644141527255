import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { AppLayout, Badge, DateRangePickerProps, SideNavigation, SideNavigationProps, Spinner, Tabs } from '@amzn/awsui-components-react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import {
  ApprovalStatus,
  ManagementPages,
  Modes,
  NavigationTypes,
  VisitorRequestStatus,
  SelfServicePages,
  VisitorTypes,
  LookupTypes,
  visitorStatusFilterStatuses,
  WelcomeApplicationSettings
} from 'src/constants/Constants';
import { TopNav } from './TopNav';
import Assets from './SelfService/Assets';
import { useBundle } from '@amzn/react-arb-tools';
import AccessLevels from './SelfService/AccessLevels';
import RequestAccess from './SelfService/RequestAccess';
import { focusManager, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  queryDelegationTypes,
  queryUserPreferences,
  queryVisitor,
  queryVisitorAssetsByPSID,
  updateUserPreferences,
} from './SelfService/utils';
import i18n from 'src/i18n';
import { useLocalizationContext } from '@amzn/react-arb-tools';
import { DEFAULT_LC_BUILDER } from '../constants/Constants';
import * as APIt from 'src/API';
import { IAccessDetailsRecord } from './SelfService/RequestAccess/AccessDetails/TablePanel';
import PendingAccessRequests from './SelfService/PendingAccessRequests';
import { queryPendingVisitorAccessLevels } from './SelfService/PendingAccessRequests/utils';
import {
  DateFunctions,
  queryAirSites,
  queryEmployeeDetails,
  queryLookupTypeValueForTypeAndDescription,
  queryLookupTypes,
  querySites,
  queryVisitorAccessLevels,
  queryVisitorAccessLevelsForApprover,
  queryVisitorAssetsForApprover,
  queryVisitorAssetsForStatus,
  queryVisitorRequestsForSite,
  userCanManageVisitorsForSite,
} from './utils';
import { queryPendingVisitorRequests } from './SelfService/PendingVisitorRequests/utils';
import RequestVendorAccess from './SelfService/RequestVendorAccess';
import RequestVisitorAccess from './SelfService/RequestVisitorAccess';
import RequestAmazonAirSiteAccess, { IVisitDetails } from './SelfService/RequestAmazonAirSiteAccess';
import { IEscort, IVisitorRequest, TVendor, TVisitor } from 'src/types';
import { IVendorAccessDetailsRecord } from './SelfService/RequestVendorAccess/AccessDetails/TablePanel';
import PendingVisitorRequests from './SelfService/PendingVisitorRequests';
import ManageAccessRequests from './Management/ManageAccessRequests';
import ManageAssets from './Management/ManageAssets';
import SelectSite from './Management/common/SelectSite';
import Administration from './Management/Administration';
import Support from './Management/Support';
import ManagementHome from './Management/Home';
import SelfServiceHome from './SelfService/Home';
import ManageVisitors from './Management/ManageVisitors';
import { IAppProps, IDisplayPages, ISelectOption } from 'src/interfaces';
import Tracker from './common/Tracker';
import { debug } from 'src/utils/commonUtils';
import Delegations from './SelfService/Delegations';
import { IQueryAccessLevelsParams, queryAccessLevels } from './SelfService/AccessLevels/utils';
import { queryDelegators } from './SelfService/Delegations/utils';
import RequestSTVBAccess from './SelfService/RequestSTVBAccess';

export let debugLogging: boolean = false;

export default function App(props: IAppProps) {
  debug(`App() props is ${JSON.stringify(props)}`);
  debug(`App() useParams() is ${JSON.stringify(useParams())}`);

  const [accessLevelQueryUser, setAccessLevelQueryUser] = useState<string>(props.employeeId.toString());
  const [accessLevels, setAccessLevels] = useState<IAccessDetailsRecord[]>([]);
  const [activeHref, setActiveHref] = React.useState(window.location.hash);
  const [activeTabId, setActiveTabId] = useState<string>('Home');
  const [admin, setAdmin] = useState<boolean>(props.admin);
  const [amazonAirSiteVisitDateRange, setAmazonAirSiteVisitDateRange] = useState<DateRangePickerProps.Value | null>(null);
  const [amazonAirSiteVisitEscorts, setAmazonAirSiteVisitEscorts] = useState<IEscort[]>([]);
  const [amazonAirSiteExternalVisitors, setAmazonAirSiteExternalVisitors] = useState<TVisitor[]>([]);
  const [amazonAirSiteOnboardedVisitors, setAmazonAirSiteOnboardedVisitors] = useState<IEscort[]>([]);
  const [amazonAirVisitDetails, setAmazonAirVisitDetails] = useState<IVisitDetails>();
  const [assetQueryUser, setAssetQueryUser] = useState<string>(props.employeeId.toString());
  const [cardholders, setCardholders] = useState<APIt.EmployeeDetails[]>([]);
  const [cardholdersQuickLinks, setCardholdersQuickLinks] = useState<string[]>([]);
  const [dailySummaryEmails, setDailySummaryEmails] = useState<boolean>(false);
  const [darkMode, setDarkMode] = useState<boolean>(props.darkMode);
  const [delegationTypes, setDelegationTypes] = useState<APIt.LookupTypeValue[]>([]);
  const [disableAutoApproval, setDisableAutoApproval] = useState<boolean>(false);
  const [disableNewVendorDayPassRequests, setDisableNewVendorDayPassRequests] = useState<boolean | undefined>(undefined);
  const [initialPageNavigatedTo, setInitialPageNavigatedTo] = useState<boolean>(false);
  const [enableDebugLogging, setEnableDebugLogging] = useState<boolean>(window.location.hostname === 'localhost.a2z.com');
  const [enableSTVBRequests, setEnableSTVBRequests] = useState<boolean | undefined>(undefined);
  const [liveEmails, setLiveEmails] = useState<boolean>(true);
  const [maxVisitorsPerEscort, setMaxVisitorsPerEscort] = useState<number>(6);
  const [manageAccessRequestsPageSize, setManageAccessRequestsPageSize] = useState<number>(15);
  const [manageAssetsStatus, setManageAssetsStatus] = useState<{approvalStatus: ApprovalStatus, admin: boolean}>(
    {
      approvalStatus: ApprovalStatus.PendingApproval,
      admin: false
    });
  const [manageAccessRequestsStatus, setManageAccessRequestsStatus] = useState<{approvalStatuses: ApprovalStatus[], admin: boolean}>(
    {
      approvalStatuses: [ApprovalStatus.PendingApproval, ApprovalStatus.PendingPOCApproval],
      admin: false
    });
  const [manageVisitorsPageSize, setManageVisitorsPageSize] = useState<number>(15);
  const [manageAssetsPageSize, setManageAssetsPageSize] = useState<number>(15);
  const [manageVisitorRequestsForSiteMaxDate, setManageVisitorRequestsForSiteMaxDate] = useState<string>(DateFunctions.getDateFromDays(1));
  const [manageVisitorRequestsForSiteMinDate, setManageVisitorRequestsForSiteMinDate] = useState<string>(DateFunctions.getDateFromDays(-1));
  const [manageVisitorRequestsForSiteStatuses, setManageVisitorRequestsForSiteStatuses] = useState<ISelectOption[]>(
    visitorStatusFilterStatuses.filter(v => v.value !== VisitorRequestStatus.CheckedOut));
  const [navigationType, setNavigationType] = useState<string>(NavigationTypes.SIDE_MENU);
  const [notificationTime, setNotificationTime] = useState<string | null>(null);
  const [notificationTimezone, setNotificationTimezone] = useState<string | null>(null);
  const [pendingRequestsAccessLevels, setPendingVisitorAccessLevels] = useState<APIt.VisitorAccessLevel[]>([]);
  const [pendingRequestsAccessLevelsForApprover, setPendingVisitorAccessLevelsForApprover] = useState<APIt.VisitorAccessLevel[]>([]);
  const [pendingVisitorAssetsCount, setPendingVisitorAssetsCount] = useState<number>(0);
  const [pendingVisitorAssetsForApprover, setPendingVisitorAssetsForApprover] = useState<APIt.VisitorAsset[]>([]);
  const [pendingVisitorRequests, setPendingVisitorRequests] = useState<APIt.VisitorRequest[]>([]);
  const [pendingVisitorRequestsForSite, setPendingVisitorRequestsForSite] = useState<APIt.VisitorRequest[]>([]);
  const [reasonForAccessRequest, setReasonForAccessRequest] = useState<string>('');
  const [reasonForVendorAccessRequest, setReasonForVendorAccessRequest] = useState<string>('');
  const [selectedSiteName, setSelectSiteName] = useState<string>('');
  const [showSelectSite, setShowSelectSite] = useState<boolean>(false);
  const [splitPanel, setSplitPanel] = useState<JSX.Element | undefined>(undefined);
  const [splitPanelOpen, setSplitPanelOpen] = useState<boolean>(false);
  const [unfocusTime, setUnfocusTime] = useState<number | null>(null);
  const [userCanManageVisitorsForSiteCheck, setUserCanManageVisitorsForSiteCheck] = useState<boolean>(false);
  const [vendorAccessLevels, setVendorAccessLevels] = useState<IVendorAccessDetailsRecord[]>([]);
  const [vendorEscorts, setVendorEscorts] = useState<IEscort[]>([]);
  const [vendors, setVendors] = useState<TVendor[]>([]);
  const [visitDateRange, setVisitDateRange] = useState<DateRangePickerProps.Value | null>(null);
  const [visitorRequest, setVisitorRequest] = useState<IVisitorRequest>();

  const [bundle, isBundleLoading] = useBundle('components.App');


  const {localizationContext, setLocalizationContext} = useLocalizationContext();

  const personSourceSystemName = 'PACS';

  const queryClient = useQueryClient();

  const queryAccessLevelsParams: IQueryAccessLevelsParams = {
    employeeId: accessLevelQueryUser,
    Orderby: 'accesslevelname',
    OrderbyDirection: 'a',
    PageNumber: 0,
    RecordsByPage: 500
  };

  const accessLevelsQuery = useQuery({
    queryKey: ['accessLevels'],
    queryFn: () => queryAccessLevels(queryAccessLevelsParams),
    retry: 3
  });

  const airSitesQuery = useQuery({
    queryKey: ['airSites'],
    queryFn: () => queryAirSites(),
    retry: 3
  });

  const currentUserEmployeeDetails = useQuery({
    queryFn: () => queryEmployeeDetails(props.username),
    queryKey: ['currentUserEmployeeDetails'],
    refetchOnWindowFocus: false,
  });

  const delegatorsQuery = useQuery({
    queryKey: ['listDelegationsByDelegateeID'],
    queryFn: () => queryDelegators(props.employeeId.toString()),
    retry: 3
  });

  const lookupTypesQuery = useQuery({
    enabled: (props.mode === Modes.MANAGEMENT),
    queryFn: () => queryLookupTypes(),
    queryKey: ['lookupTypes'],
    retry: 3,
  });

  const manageAssetsQuery = useQuery({
    enabled: (props.mode == Modes.MANAGEMENT),
    onSuccess: (data) => data ? setPendingVisitorAssetsForApprover(data) : setPendingVisitorAssetsForApprover([]),
    queryKey: ['manageAssets'],
    queryFn: () => {
      if (manageAssetsStatus.admin)
        return queryVisitorAssetsForStatus(manageAssetsStatus.approvalStatus);
      else
        return queryVisitorAssetsForApprover(props.username, manageAssetsStatus.approvalStatus);
    },
    refetchOnWindowFocus: false,
    retry: 3,
  });

  const manageVisitorAccessLevelsQuery = useQuery({
    enabled: (props.mode == Modes.MANAGEMENT),
    onSuccess: (data) => data ? setPendingVisitorAccessLevelsForApprover(data) : setPendingVisitorAccessLevelsForApprover([]),
    queryKey: ['manageVisitorAccessLevels'],
    queryFn: () => {
      if (manageAccessRequestsStatus.admin)
        return queryVisitorAccessLevels(manageAccessRequestsStatus.approvalStatuses);
      else
        return queryVisitorAccessLevelsForApprover(props.username, manageAccessRequestsStatus.approvalStatuses) as unknown as Promise<APIt.VisitorAccessLevel[]>;
    },
    refetchOnWindowFocus: false,
    retry: 3,
  });

  const manageVisitorRequestsQuery = useQuery({
    enabled: (props.mode == Modes.MANAGEMENT && selectedSiteName != ''),
    onSuccess: (data) => {
      data ? setPendingVisitorRequestsForSite(data) : setPendingVisitorRequestsForSite([]);
      setShowSelectSite(false);
    },
    queryKey: ['manageVisitorRequests'],
    queryFn: () => {
      if (!admin && !userCanManageVisitorsForSiteCheck) return [];
      return queryVisitorRequestsForSite(
        selectedSiteName,
        manageVisitorRequestsForSiteStatuses && manageVisitorRequestsForSiteStatuses.length >= 0 ? manageVisitorRequestsForSiteStatuses : [],
        manageVisitorRequestsForSiteMinDate,
        manageVisitorRequestsForSiteMaxDate);
    },
    refetchOnWindowFocus: false,
    retry: 3,
  });

  const sitesQuery = useQuery({
    queryKey: ['sites'],
    queryFn: () => querySites(true),
    retry: 3
  });

  debugLogging = enableDebugLogging;

  const handleFocus = () => {
    if (unfocusTime && Date.now() - unfocusTime > 60000) {
      manageAssetsQuery.refetch();
      manageVisitorAccessLevelsQuery.refetch();
      manageVisitorRequestsQuery.refetch();
    }
    setUnfocusTime(null);
  };

  const handleUnfocus = () => {
    setUnfocusTime(Date.now());
  };

  focusManager.setEventListener(() => {
    if (typeof window !== 'undefined' && window.addEventListener) {
      window.addEventListener('focus', handleFocus, false);
      window.addEventListener('blur', handleUnfocus, false);
    }

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleUnfocus);
    }
  });

  const delegationTypesQuery = useQuery({
    enabled: (props.mode == Modes.SELF_SERVICE),
    onSuccess: (data) => data ? setDelegationTypes(data) : setDelegationTypes([]),
    queryKey: ['delegationTypes'],
    queryFn: () => queryDelegationTypes(),
    retry: 3
  });

  const pendingVisitorAccessLevelsQuery = useQuery({
    enabled: (props.mode == Modes.SELF_SERVICE),
    onSuccess: (data) => data ? setPendingVisitorAccessLevels(data) : setPendingVisitorAccessLevels([]),
    queryKey: ['pendingVisitorAccessLevels'],
    queryFn: () => queryPendingVisitorAccessLevels(props.username, props.username),
    retry: 3,
  });

  const pendingVisitorRequestsQuery = useQuery({
    enabled: (props.mode == Modes.SELF_SERVICE),
    onSuccess: (data) => data ? setPendingVisitorRequests(data) : setPendingVisitorRequests([]),
    queryKey: ['pendingVisitorRequests'],
    queryFn: () => queryPendingVisitorRequests(props.username),
    retry: 3,
  });

  const checkDisableVendorDayPassRequests = async (selfServicePrefs: any | null) => {
    const disableNewVendorDayPassRequests = await queryLookupTypeValueForTypeAndDescription(LookupTypes.WelcomeApplicationSettings, WelcomeApplicationSettings.DisableNewVendorDayPassRequests);
    debug(`App() checkDisableVendorDayPassRequests() onSuccess(data) disableNewVendorDayPassRequests is ${JSON.stringify(disableNewVendorDayPassRequests)}`);
    setDisableNewVendorDayPassRequests(disableNewVendorDayPassRequests?.value.toLowerCase() === 'yes');
    if (disableNewVendorDayPassRequests && disableNewVendorDayPassRequests.value.toLowerCase() === 'yes') {
      if (selfServicePrefs?.initialPage === 'Vendor Day Pass Request') {
        selfServicePrefs.initialPage = 'Home';
        setActiveTabId('Home');
      }
    }
    if (!disableNewVendorDayPassRequests || disableNewVendorDayPassRequests.value.toLowerCase() !== 'yes') {
      setDisableNewVendorDayPassRequests(false);
      setActiveTabId(selfServicePrefs.initialPage ?? 'Home');
    }
  };

  const userPrefsQuery = useQuery({
    queryKey: ['userPrefs'],
    queryFn: () => queryUserPreferences(props.username),
    onSuccess: async (data) => {
      const globalPrefsString = data?.global;
      if (globalPrefsString) {
        try {
          const globalPrefs = JSON.parse(globalPrefsString);
          const language = globalPrefs['language']['value'];
          setLiveEmails(globalPrefs['liveEmails'] == null ? true : globalPrefs['liveEmails'] );
          setDailySummaryEmails(globalPrefs['dailySummaryEmails'] == null ? false : globalPrefs['dailySummaryEmails']);
		      i18n.changeLanguage(language);
          setNotificationTimezone(globalPrefs['notificationTimezone']);
          setNotificationTime(globalPrefs['notificationTime']);
		      setLocalizationContext(DEFAULT_LC_BUILDER.withLocale(language).build());
        } catch(error) {
          debug(`App() userPrefsQuery() onSuccess(data) error is ${error}`);
        }
      }
      const managementPrefsString = data?.management || '{}';
      if (props.mode == Modes.MANAGEMENT && managementPrefsString) {
        try {
          const managementPrefs = JSON.parse(managementPrefsString);
          if (managementPrefs.darkMode != undefined) setDarkMode(managementPrefs.darkMode);
          if (!selectedSiteName && managementPrefs.lastSite) siteSelected(managementPrefs.lastSite);
          if (managementPrefs.manageVisitorsPageSize) setManageVisitorsPageSize(managementPrefs.manageVisitorsPageSize);
          if (managementPrefs.manageAccessRequestsPageSize) setManageAccessRequestsPageSize(managementPrefs.manageAccessRequestsPageSize);
          if (managementPrefs.manageAssetsPageSize) setManageAssetsPageSize(managementPrefs.manageAssetsPageSize);
          if (managementPrefs.navigationType) setNavigationType(managementPrefs.navigationType);
          if (!initialPageNavigatedTo) {
            await checkDisableVendorDayPassRequests(null);
          }
          if (props.page && !initialPageNavigatedTo) {
            setActiveTabId(props.page);
            const managementPage = ManagementPages.find(mp => mp.name == props.page);
            setActiveHref(managementPage?.route ?? '/management/home');
            setInitialPageNavigatedTo(true);
          } else if (!initialPageNavigatedTo) {
            setActiveTabId(managementPrefs.initialPage ?? 'Home');
            const managementPage = ManagementPages.find(mp => mp.name == managementPrefs.initialPage);
            setActiveHref(managementPage?.route ?? '/management/home');
            setInitialPageNavigatedTo(true);
          }
        } catch(error) {
          debug(`App() userPrefsQuery() onSuccess(data) error is ${error}`);
        }
      }
      const selfServicePrefsString = data?.selfService || '{}';
      if (props.mode == Modes.SELF_SERVICE && selfServicePrefsString) {
        try {
          const selfServicePrefs = JSON.parse(selfServicePrefsString);
          if (selfServicePrefs.darkMode != undefined) setDarkMode(selfServicePrefs.darkMode);
          if (selfServicePrefs.navigationType) setNavigationType(selfServicePrefs['navigationType']);
          if (selfServicePrefs.cardholdersQuickLinks) setCardholdersQuickLinks(selfServicePrefs['cardholdersQuickLinks']);
          if (!initialPageNavigatedTo) {
            let enableSTVBRequests, maxVisitorsPerEscort;
            try {
              await checkDisableVendorDayPassRequests(selfServicePrefs);
              enableSTVBRequests = await queryLookupTypeValueForTypeAndDescription(LookupTypes.WelcomeApplicationSettings, WelcomeApplicationSettings.STVBEnabled);
              debug(`App() userPrefsQuery() onSuccess(data) enableSTVBRequests is ${JSON.stringify(enableSTVBRequests)}`);
              setEnableSTVBRequests(enableSTVBRequests?.value.toLowerCase() === 'yes');
              maxVisitorsPerEscort = await queryLookupTypeValueForTypeAndDescription(LookupTypes.WelcomeApplicationSettings, WelcomeApplicationSettings.MaxVisitorsPerEscort);
              debug(`App() userPrefsQuery() onSuccess(data) maxVisitorsPerEscort is ${JSON.stringify(maxVisitorsPerEscort)}`);
              setMaxVisitorsPerEscort(parseInt(maxVisitorsPerEscort?.value));
            } catch(error) {
              debug(`App() userPrefsQuery() onSuccess(data) error is ${error}`);
            }
            debug(`App() userPrefsQuery() onSuccess(data) selfServicePrefs is ${JSON.stringify(selfServicePrefs)}`);
            if (!enableSTVBRequests || enableSTVBRequests.value.toLowerCase() !== 'yes') {
              if (selfServicePrefs.initialPage === 'Short Term Vendor Request') {
                selfServicePrefs.initialPage = 'Home';
                setActiveTabId('Home');
              }
              setEnableSTVBRequests(false);
            }
            if (enableSTVBRequests  && enableSTVBRequests.value.toLowerCase() === 'yes') {
              setEnableSTVBRequests(true);
              setActiveTabId(selfServicePrefs.initialPage ?? 'Home');
            }
            const selfServicePage = SelfServicePages.find(ssp => ssp.name == selfServicePrefs.initialPage);
            setActiveHref(selfServicePage?.route ?? '/selfservice/home');
            setInitialPageNavigatedTo(true);
          }
        } catch(error) {
          debug(`App() userPrefsQuery() onSuccess(data) error is ${error}`);
        }
      }
    },
    retry: 3,
  });

  const visitorQuery = useQuery({
    enabled: (props.mode == Modes.SELF_SERVICE),
    onSuccess: async (data) => {
      debug(`App() visitorQuery() onSuccess data is ${JSON.stringify(data)}`);
      if (data?.id) visitorAssetsQuery.refetch();
    },
    onError: () => {debug(`visitorQuery() onError`)},
    queryKey: ['visitor'],
    queryFn: async () => await queryVisitor(props.employeeId.toString(), personSourceSystemName),
    retry: 3,
  });

  const visitorAssetsQuery = useQuery({
    onSuccess: (data) => {
      if (data) {
        const pendingCount = data.reduce(
          (total, va) => {
            va.approval_status == ApprovalStatus.PendingApproval ? total++ : total;
            return total;
          },0);
        setPendingVisitorAssetsCount(pendingCount);
      }
    },
    queryKey: ['visitorAssets'],
    queryFn: async () =>
      await queryVisitorAssetsByPSID(assetQueryUser),
    refetchOnWindowFocus: false,
    retry: 3,
  });

  const siteSelected = async (site: string) => {
    (await userCanManageVisitorsForSite(props.username, site))
      ? setUserCanManageVisitorsForSiteCheck(true)
      : setUserCanManageVisitorsForSiteCheck(false);
    setSelectSiteName(site);
  };

  const displayPages = (): IDisplayPages[] => {
    let displayPages: IDisplayPages[] = [];
    if (props.mode == Modes.SELF_SERVICE) {
      displayPages = [
        {
          label: bundle.getMessage('home'),
          id: 'Home',
          content:
            <SelfServiceHome
              setPageCallback={(link: string, tab: string) => {
                navigationType == NavigationTypes.SIDE_MENU ? setActiveHref(link) : setActiveTabId(tab)
              }}
              username={props.username}
            />
        },
        {
          label: bundle.getMessage('request-access'),
          id: 'Request Access',
          content:
            <RequestAccess
              accessLevels={accessLevels}
              disableAutoApproval={disableAutoApproval}
              cardholders={cardholders}
              cardholdersQuickLinks={cardholdersQuickLinks}
              darkMode={darkMode}
              setCardholdersQuickLinksCallback={setCardholdersQuickLinks}
              mode={props.mode}
              reasonForAccessRequest={reasonForAccessRequest}
              setAccessLevelsCallback={setAccessLevels}
              setCardholdersCallback={setCardholders}
              setReasonForAccessRequestCallback={setReasonForAccessRequest}
              username={props.username}
            />
        },
        {
          badge: <Badge color='blue'>{pendingRequestsAccessLevels.length > 0 ? pendingRequestsAccessLevels.length : undefined}</Badge>,
          id: 'Pending Access Requests',
          label: <>{bundle.getMessage('pending-access-requests')}&nbsp;<Badge color='blue'>{pendingRequestsAccessLevels.length > 0 ? pendingRequestsAccessLevels.length : undefined}</Badge></>,
          content:
            <PendingAccessRequests
              mode={props.mode}
              pendingVisitorAccessLevelsQuery={pendingVisitorAccessLevelsQuery}
              username={props.username}
            />
        },
        {
          id: 'Register A Visitor',
          label: bundle.getMessage('register-a-visitor'),
          content:
            <RequestVisitorAccess
              cardholdersQuickLinks={cardholdersQuickLinks}
              darkMode={darkMode}
              maxVisitorsPerEscort={maxVisitorsPerEscort}
              mode={props.mode}
              setCardholdersQuickLinksCallback={setCardholdersQuickLinks}
              setVisitDateRangeCallback={setVisitDateRange}
              setVisitorRequestCallback={setVisitorRequest}
              username={props.username}
              visitDateRange={visitDateRange}
              visitorRequest={visitorRequest || null}
            />
        },
        {
          badge: <Badge color='blue'>{pendingVisitorRequests.length > 0 ? pendingVisitorRequests.length : undefined}</Badge>,
          id: 'Pending Visitor Requests',
          label: <>{bundle.getMessage('pending-visitor-requests')}&nbsp;<Badge color='blue'>{pendingVisitorRequests.length > 0 ? pendingVisitorRequests.length : undefined}</Badge></>,
          content:
            <PendingVisitorRequests
              mode={props.mode}
              username={props.username}
            />
        },
        {
          badge: <Badge color='blue'>{pendingVisitorAssetsCount > 0 ? pendingVisitorAssetsCount : undefined}</Badge>,
          id: 'Assets',
          label:
            <>
              {bundle.getMessage('assets')}&nbsp;<Badge color='blue'>{pendingVisitorAssetsCount > 0 ? pendingVisitorAssetsCount : undefined}</Badge>
            </>,
          content:
            <Assets
              assetQueryUser={assetQueryUser}
              delegatorsQuery={delegatorsQuery}
              setAssetQueryUserCallback={setAssetQueryUser}
              disableAutoApproval={disableAutoApproval}
              mode={props.mode}
              username={props.username}
              empId={props.employeeId.toString()}
              visitorAssetsQuery={visitorAssetsQuery}
              visitorId={visitorQuery?.data?.id}
            />
        },
        {
          label: bundle.getMessage('vendor-day-pass-request'),
          id: 'Vendor Day Pass Request',
          content:
            <RequestVendorAccess
              accessLevels={vendorAccessLevels}
              cardholdersQuickLinks={cardholdersQuickLinks}
              darkMode={darkMode}
              disableAutoApproval={disableAutoApproval}
              escorts={vendorEscorts}
              mode={props.mode}
              reasonForAccessRequest={reasonForVendorAccessRequest}
              setAccessLevelsCallback={setVendorAccessLevels}
              setCardholdersQuickLinksCallback={setCardholdersQuickLinks}
              setEscortsCallback={setVendorEscorts}
              setVendorsCallback={setVendors}
              setReasonForAccessRequestCallback={setReasonForVendorAccessRequest}
              username={props.username}
              vendors={vendors}
              visitorId={visitorQuery?.data?.id}
            />
        },
        {
          label: bundle.getMessage('short-term-vendor-badge-request'),
          id: 'Short Term Vendor Request',
          content:
            <RequestSTVBAccess
              accessLevels={vendorAccessLevels}
              cardholdersQuickLinks={cardholdersQuickLinks}
              darkMode={darkMode}
              disableAutoApproval={disableAutoApproval}
              escorts={vendorEscorts}
              mode={props.mode}
              reasonForAccessRequest={reasonForVendorAccessRequest}
              setAccessLevelsCallback={setVendorAccessLevels}
              setCardholdersQuickLinksCallback={setCardholdersQuickLinks}
              setEscortsCallback={setVendorEscorts}
              setVendorsCallback={setVendors}
              setReasonForAccessRequestCallback={setReasonForVendorAccessRequest}
              username={props.username}
              vendors={vendors}
              visitorId={visitorQuery?.data?.id}
            />
        },
        {
          label: bundle.getMessage('view-access'),
          id: 'View Access',
          content:
            <AccessLevels accessLevelsQuery={accessLevelsQuery} accessLevelQueryUser={accessLevelQueryUser} delegatorsQuery={delegatorsQuery} employeeId={props.employeeId} username={props.username} setAccessLevelsCallback={setAccessLevelQueryUser} />
        },
        {
          label: bundle.getMessage('visit-amazon-air-site'),
          id: 'Visit Amazon Air Site',
          content:
            <RequestAmazonAirSiteAccess
              cardholdersQuickLinks={cardholdersQuickLinks}
              darkMode={darkMode}
              escorts={amazonAirSiteVisitEscorts}
              externalVisitors={amazonAirSiteExternalVisitors}
              maxVisitorsPerEscort={maxVisitorsPerEscort}
              mode={props.mode}
              onboardedVisitors={amazonAirSiteOnboardedVisitors}
              setCardholdersQuickLinksCallback={setCardholdersQuickLinks}
              setEscortsCallback={setAmazonAirSiteVisitEscorts}
              setExternalVisitorsCallback={setAmazonAirSiteExternalVisitors}
              setOnboardedVisitorsCallback={setAmazonAirSiteOnboardedVisitors}
              setVisitDateRangeCallback={setAmazonAirSiteVisitDateRange}
              setVisitDetailsCallback={setAmazonAirVisitDetails}
              username={props.username}
              visitorId={visitorQuery?.data?.id}
              visitDateRange={amazonAirSiteVisitDateRange}
              visitDetails={amazonAirVisitDetails}
            />
        },
        {
          id: 'Delegation',
          label: bundle.getMessage('delegation'),
          content:
            <Delegations
              delegatorsQuery={delegatorsQuery}
              delegationTypesQuery={delegationTypesQuery}
              employeeId={props.employeeId.toString()}
              mode={props.mode}
              username={props.username}
            />
        },
      ];
      if (disableNewVendorDayPassRequests === true) {
        displayPages = displayPages.filter(dp => dp.id !== 'Vendor Day Pass Request');
      }
      if (enableSTVBRequests !== true) {
        displayPages = displayPages.filter(dp => dp.id !== 'Short Term Vendor Request');
      }
    } else if (props.mode == Modes.MANAGEMENT) {
      displayPages = [
        {
          label: bundle.getMessage('home'),
          id: 'Home',
          content:
            <ManagementHome
              setPageCallback={(link: string, tab: string) => {
                navigationType == NavigationTypes.SIDE_MENU ? setActiveHref(link) : setActiveTabId(tab)
              }}
              siteId={selectedSiteName}
              username={props.username}
            />
        },
        {
          badge:
            <Badge color='blue'>
              {pendingRequestsAccessLevelsForApprover.filter(v => v.status == ApprovalStatus.PendingApproval).length > 0 ? pendingRequestsAccessLevelsForApprover.filter(v => v.status == ApprovalStatus.PendingApproval).length : undefined}
            </Badge>,
          content:
            <ManageAccessRequests
              admin={admin}
              manageVisitorAccessLevelsQuery={manageVisitorAccessLevelsQuery}
              manageVisitorAccessLevelQueryAdmin={manageAccessRequestsStatus.admin}
              mode={props.mode}
              pageSize={manageAccessRequestsPageSize}
              setPageSizePrefCallback={setManageAccessRequestsPageSize}
              setSplitPanelCallback={setSplitPanel}
              setSplitPanelOpenCallback={setSplitPanelOpen}
              setStatusFilterCallback={setManageAccessRequestsStatus}
              username={props.username}
            />,
          id: 'Manage Access Requests',
          label:
            <>
              {bundle.getMessage('manage-access-requests')}&nbsp;
              <Badge color='blue'>
                {pendingRequestsAccessLevelsForApprover.filter(v => v.status == ApprovalStatus.PendingApproval).length > 0 ? pendingRequestsAccessLevelsForApprover.filter(v => v.status == ApprovalStatus.PendingApproval).length : undefined}
              </Badge>
            </>,
        },
        {
          badge:
            <Badge color='blue'>
              {pendingVisitorRequestsForSite.filter(
                v => v.status != VisitorRequestStatus.CheckedOut).length > 0
                ? pendingVisitorRequestsForSite.filter(v => v.status != VisitorRequestStatus.CheckedOut && (airSitesQuery.data?.find(s => s.SiteCode == selectedSiteName) !== undefined || v.visitor_type !== VisitorTypes.Employee)).length
                : undefined}
            </Badge>,
          content:
            <ManageVisitors
              admin={admin}
              airSite={airSitesQuery.data?.find(s => s.SiteCode == selectedSiteName) !== undefined || false}
              cardholdersQuickLinks={cardholdersQuickLinks}
              darkMode={darkMode}
              manageVisitorRequestsQuery={manageVisitorRequestsQuery}
              minDateFilter={manageVisitorRequestsForSiteMinDate}
              maxDateFilter={manageVisitorRequestsForSiteMaxDate}
              pageSize={manageVisitorsPageSize}
              setCardholdersQuickLinksCallback={setCardholdersQuickLinks}
              setMaxDateCallback={setManageVisitorRequestsForSiteMaxDate}
              setMinDateCallback={setManageVisitorRequestsForSiteMinDate}
              setPageSizePrefCallback={setManageVisitorsPageSize}
              setSplitPanelCallback={setSplitPanel}
              setSplitPanelOpenCallback={setSplitPanelOpen}
              setStatusFilterCallback={setManageVisitorRequestsForSiteStatuses}
              setVisitorRequestCallback={setVisitorRequest}
              siteCode={selectedSiteName}
              statusesFilter={manageVisitorRequestsForSiteStatuses}
              userCanManageVisitorsForSite={userCanManageVisitorsForSiteCheck}
              username={props.username}
            />,
          id: 'Manage Visitors',
          label:
            <>
              {bundle.getMessage('manage-visitors')}{selectedSiteName ? ` (${selectedSiteName})` : ''}&nbsp;
              <Badge color='blue'>
                {pendingVisitorRequestsForSite.filter(v => v.status != VisitorRequestStatus.CheckedOut).length > 0
                  ? pendingVisitorRequestsForSite.filter(v => v.status != VisitorRequestStatus.CheckedOut && (airSitesQuery.data?.find(s => s.SiteCode == selectedSiteName) !== undefined || v.visitor_type !== VisitorTypes.Employee)).length
                  : undefined}
              </Badge>
            </>,
        },
        {
          badge:
            <Badge color='blue'>
              {pendingVisitorAssetsForApprover.filter(v => v.approval_status == ApprovalStatus.PendingApproval).length > 0 ?pendingVisitorAssetsForApprover.filter(v => v.approval_status == ApprovalStatus.PendingApproval).length : undefined}
            </Badge>,
          content:
            <ManageAssets
              admin={admin}
              manageAssetsQuery={manageAssetsQuery}
              manageAssetsQueryAdmin={manageAssetsStatus.admin}
              mode={props.mode}
              pageSize={manageAssetsPageSize}
              setPageSizePrefCallback={setManageAssetsPageSize}
              setSplitPanelCallback={setSplitPanel}
              setSplitPanelOpenCallback={setSplitPanelOpen}
              setStatusFilterCallback={setManageAssetsStatus}
              siteCode={selectedSiteName}
              userCanManageVisitorsForSite={userCanManageVisitorsForSiteCheck}
              username={props.username}
            />,
          label:
            <>
              {bundle.getMessage('manage-assets')}&nbsp;
              <Badge color='blue'>
                {pendingVisitorAssetsForApprover.filter(v => v.approval_status == ApprovalStatus.PendingApproval).length > 0 ? pendingVisitorAssetsForApprover.filter(v => v.approval_status == ApprovalStatus.PendingApproval).length : undefined}
              </Badge>
            </>,
          id: 'Manage Assets',
        },
      ];
      if (admin) displayPages.push(
        {
          label: bundle.getMessage('administration'),
          id: 'Administration',
          content: <Administration username={props.username} />
        },
        {
          label: bundle.getMessage('support'),
          id: 'Support',
          content: <Support/>
        },
      );
    } else {
      console.error(`App() received invalid mode property, ${props.mode}`);
    }

    return displayPages;
  };

  const displayMenuItems = (): SideNavigationProps.Item[] => {
    let menuItems: SideNavigationProps.Link[] = [];
    const pages = displayPages();
    if (props.mode == Modes.MANAGEMENT) {
      menuItems = ManagementPages.filter(mp => ((mp.admin && admin) || (!mp.admin)))
        .sort((a,b) => a.order - b.order)
        .map(mp => {
          return {
            href: mp.route,
            info: pages.find(p => p.id == mp.name)?.badge || undefined,
            text: (mp.code == 'manage-visitors' && selectedSiteName) ? bundle.getMessage(mp.code) + ` (${selectedSiteName})` : bundle.getMessage(mp.code),
            type: 'link',
          }
        });
    } else if (props.mode == Modes.SELF_SERVICE) {
      menuItems = SelfServicePages.sort((a,b) => a.order - b.order)
        .map(mp => {
          return {
            href: mp.route,
            info: pages.find(p => p.id == mp.name)?.badge || undefined,
            text: bundle.getMessage(mp.code),
            type: 'link',
          }
        });
      if (disableNewVendorDayPassRequests)
        menuItems = menuItems.filter(mi => mi.text !== bundle.getMessage('vendor-day-pass-request'));
      if (enableSTVBRequests !== true)
        menuItems = menuItems.filter(mi => mi.text !== bundle.getMessage('short-term-vendor-badge-request'));
    }
    return menuItems;
  };

  const appLayoutContent = () => {
    const tabs = displayPages();
    if (navigationType == NavigationTypes.SIDE_MENU) {
      if (props.mode == Modes.MANAGEMENT) {
        return(tabs.find(tab => tab.id == ManagementPages.find(ssp => ssp.route == activeHref)?.name))?.content;
      } else if (props.mode == Modes.SELF_SERVICE) {
        return(tabs.find(tab => tab.id == SelfServicePages.find(ssp => ssp.route == activeHref)?.name))?.content;
      }
    }
    return(
      <Tabs
        tabs={displayPages()}
        variant='container'
        activeTabId={activeTabId}
        onChange={e => {
          setActiveTabId(e.detail.activeTabId);
          setSplitPanel(<></>);
          setSplitPanelOpen(false);
        }}
      />
    );
  };

  const navigation = () => {
    if (navigationType == NavigationTypes.TABS) return undefined;
    return(
      <SideNavigation
        activeHref={activeHref}
        header={
          {
            href: `/${props.mode == Modes.MANAGEMENT ? 'management' : 'selfservice'}/home`,
            text: props.mode == Modes.MANAGEMENT ? bundle.getMessage('management') : bundle.getMessage('self-service')
          }
        }
        items={displayMenuItems()}
        onFollow={event => {
          if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
            setSplitPanel(<></>);
            setSplitPanelOpen(false);
          }
        }}
      />
    );
  };

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('awsui-polaris-dark-mode');
    } else {
      document.body.classList.remove('awsui-polaris-dark-mode');
    }
  }, [darkMode]);

  const updateUserPrefsMutation = useMutation({
    mutationFn: updateUserPreferences,
  });

  useEffect(() => {
    manageVisitorRequestsQuery.refetch();
    const userPrefs = userPrefsQuery.data;
    if (!userPrefs?.management) return;
    const managementUserPrefs = JSON.parse(userPrefs.management);
    managementUserPrefs.lastSite = selectedSiteName;
    updateUserPrefsMutation.mutate({
      ...userPrefs,
      management: JSON.stringify(managementUserPrefs)
    });
  }, [selectedSiteName]);

  useEffect(() => {
    const userPrefs = userPrefsQuery.data;
    if (!userPrefs?.selfService) return;
    const selfServiceUserPrefs = JSON.parse(userPrefs.selfService);
    selfServiceUserPrefs.cardholdersQuickLinks = cardholdersQuickLinks;
    updateUserPrefsMutation.mutate({
      ...userPrefs,
      selfService: JSON.stringify(selfServiceUserPrefs)
    });
  }, [cardholdersQuickLinks]);

  useEffect(() => {
    const userPrefs = userPrefsQuery.data;
    if (!userPrefs?.management) return;
    const managementUserPrefs = JSON.parse(userPrefs.management);
    managementUserPrefs.manageVisitorsPageSize = manageVisitorsPageSize;
    managementUserPrefs.manageAccessRequestsPageSize = manageAccessRequestsPageSize;
    managementUserPrefs.manageAssetsPageSize = manageAssetsPageSize;
    updateUserPrefsMutation.mutate({
      ...userPrefs,
      management: JSON.stringify(managementUserPrefs)
    });
  }, [manageVisitorsPageSize, manageAccessRequestsPageSize, manageAssetsPageSize]);

  useEffect(() => {
    if (visitorQuery?.data?.id) {
      visitorAssetsQuery.refetch();
    }
  }, [queryClient.getQueryData(['visitor'])]);

  useEffect(() => {
    if (selectedSiteName == ''
    && (activeHref == '/management/managevisitors' || activeTabId == 'Manage Visitors'))
      setShowSelectSite(true);
  }, [activeHref, activeTabId]);

  useEffect(() => {
    setAdmin(props.admin);
  }, [props.admin]);

  useEffect(() => {
    (props.mode == Modes.MANAGEMENT) ? window.name = 'welcomeManagement' : window.name = 'welcomeSelfService';
  }, []);

  useEffect(() => {
    visitorAssetsQuery.refetch();
  }, [assetQueryUser]);

  useEffect(() => {
    accessLevelsQuery.refetch();
  }, [accessLevelQueryUser]);

  if (isBundleLoading) return <Spinner/>;

  return (
    <>
      {
        props.mode == Modes.MANAGEMENT && showSelectSite
        &&
        <SelectSite
          selectedSiteName={selectedSiteName}
          setSelectedSiteCallback={siteSelected}
          setSiteSelectVisibleCallback={() => setShowSelectSite(false)}
        />
      }
      <div
        className="awsui"
        id='topNavigation'
        style={{position: 'sticky', top: 0, zIndex: 1002}}
      >
        <TopNav
          admin={admin}
          dailySummaryEmails={dailySummaryEmails}
          darkMode={darkMode}
          disableNewVendorDayPassRequests={disableNewVendorDayPassRequests}
          disableAutoApproval={disableAutoApproval}
          enableSTVBRequests={enableSTVBRequests}
          enableDebugLogging={enableDebugLogging}
          liveEmails={liveEmails}
          mode={props.mode}
          notificationTime={notificationTime}
          notificationTimezone={notificationTimezone}
          setAdminCallback={setAdmin}
          setDisableAutoApprovalCallback={setDisableAutoApproval}
          setDarkModeCallback={setDarkMode}
          setEnableDebugLoggingCallback={setEnableDebugLogging}
          setShowSelectSite={() => setShowSelectSite(true)}
          setTabCallback={setActiveTabId}
          siteCode={selectedSiteName}
          username={props.username}
          userPrefsQuery={userPrefsQuery}
          userCanManageVisitorsForSite={userCanManageVisitorsForSiteCheck}
        />
      </div>
      <AppLayout
        ariaLabels={{ navigationClose: 'close' }}
        content={appLayoutContent()}
        contentType='table'
        disableBodyScroll={true}
        disableContentPaddings
        headerSelector='#topNavigation'
        navigation={navigation()}
        navigationHide={navigation() == undefined}
        splitPanel={splitPanel}
        splitPanelOpen={splitPanelOpen}
        onSplitPanelToggle={() => setSplitPanelOpen(!splitPanelOpen)}
        stickyNotifications
        toolsHide={true}
      />
      <Tracker username={props.username}/>
      <ReactQueryDevtools/>
    </>
  );
}
